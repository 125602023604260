import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')

export default async function getWarehouseList() {
  const warehouseOptions = localStorage.getItem('warehouse_list')
    ? JSON.parse(localStorage.getItem('warehouse_list'))
    : (await ResourceRepository.getWarehouseListForDropDown()).data.data

  if (!localStorage.getItem('warehouse_list')) {
    localStorage.setItem('warehouse_list', JSON.stringify(warehouseOptions))
  }

  return warehouseOptions
}
