<template>
  <div>
    <b-card title="Assigned Orders Reports">
      <b-row>
        <b-col
          cols="12"
          lg="6"
          class="my-1 align-items-start"
        >
          <v-select
            v-model="selectedReport"
            :reduce="option => option.key"
            label="name"
            placeholder="Select Required Report"
            :options="filteredOptions"
          />
        </b-col>
      </b-row>
    </b-card>
    <div v-if="selectedReport === 'received_orders_to_origin_warehouse_report_key'">
      <ReceivedOrderOriginWarehouse />
    </div>
    <div v-if="selectedReport === 'delivery_riders_key'">
      <AssignOrdersDeliveryRider />
    </div>
    <div v-if="selectedReport === 'pickup_riders_key'">
      <AssignOrdersPickupRider />
    </div>
    <div v-if="selectedReport === 'dispatched_from_origin_warehouse__key'">
      <AssignOrderWarehouse />
    </div>
    <div v-if="selectedReport === 'rescheduled_key'">
      <AssignOrdersRescheduled />
    </div>
    <div v-if="selectedReport === 'return_to_client_key'">
      <ReturnOrderMerchant />
    </div>
    <div v-if="selectedReport === 'return_to_origin_warehouse_failed_key'">
      <ReturnOrderOriginWarehouse />
    </div>
    <div v-if="selectedReport === 'return_to_origin_warehouse_invalid_destination_key'">
      <ReturnOrderOriginWarehouseID />
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AssignOrdersDeliveryRider from './AssignOrdersDeliveryRider/AssignOrdersDeliveryRider.vue'
import AssignOrdersPickupRider from './AssignOrdersPickupRider/AssignOrdersPickupRider.vue'
import AssignOrderWarehouse from './AssignOrderWarehouse/AssignOrderWarehouse.vue'
import ReturnOrderMerchant from './ReturnOrderMerchant/ReturnOrderMerchant.vue'
import ReturnOrderOriginWarehouse from './ReturnOrderOriginWarehouse/ReturnOrderOriginWarehouse.vue'
import ReturnOrderOriginWarehouseID from './ReturnOrderOriginWarehouseId/ReturnOrderOriginWarehouseId.vue'
import ReceivedOrderOriginWarehouse from './ReceivedOrderOriginWarehouse/ReceivedOrderOriginWarehouse.vue'
import AssignOrdersRescheduled from './AssignOrdersRescheduled/AssignOrdersRescheduled.vue'

export default {
  components: {
    AssignOrdersDeliveryRider,
    AssignOrdersPickupRider,
    AssignOrderWarehouse,
    ReturnOrderMerchant,
    ReturnOrderOriginWarehouse,
    ReturnOrderOriginWarehouseID,
    ReceivedOrderOriginWarehouse,
    AssignOrdersRescheduled,
    BCard,
    BRow,
    BCol,
    vSelect,
  },
  data() {
    return {
      selectedReport: '',
      reportOptions: [
        {
          name: 'RECEIVED ORDERS TO ORIGIN WAREHOUSE', component: 'ReceivedOrderOriginWarehouse', key: 'received_orders_to_origin_warehouse_report_key', permission: 'received-orders-to-origin-warehouse-report',
        },
        {
          name: 'DELIVERY RIDER', component: 'AssignOrdersDeliveryRider', key: 'delivery_riders_key', permission: 'assigned-orders-to-delivery-rider-report',
        },
        {
          name: 'PICKUP RIDER', component: 'AssignOrdersPickupRider', key: 'pickup_riders_key', permission: 'assigned-orders-to-pickup-rider-report',
        },
        {
          name: 'DISPATCHED FROM ORIGIN WAREHOUSE', component: 'AssignOrderWarehouse', key: 'dispatched_from_origin_warehouse__key', permission: 'assigned-orders-to-warehouse-report',
        },
        {
          name: 'RESCHEDULED', component: 'AssignOrdersRescheduled', key: 'rescheduled_key', permission: 'assigned-orders-rescheduled-report',
        },
        {
          name: 'RETURN TO CLIENT', component: 'ReturnOrderMerchant', key: 'return_to_client_key', permission: 'returned-orders-to-merchant-report',
        },
        {
          name: 'RETURN TO ORIGIN WAREHOUSE(FAILED TO DELIVER)', component: 'ReturnOrderOriginWarehouse', key: 'return_to_origin_warehouse_failed_key', permission: 'returned-orders-to-origin-warehouse-report',
        },
        {
          name: 'RETURN TO ORIGIN WAREHOUSE(INVALID DESTINATION)', component: 'ReturnOrderOriginWarehouseID', key: 'return_to_origin_warehouse_invalid_destination_key', permission: 'returned-orders-to-origin-warehouse-invalid-destination-report',
        },
      ],
    }
  },
  computed: {
    filteredOptions() {
      return this.reportOptions.filter(option => this.hasPermission(option.permission))
    },
  },
  methods: {
    hasPermission(permission) {
      return this.$store.getters.permissions.includes(permission)
    },
  },
}
</script>
<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select';

</style>
