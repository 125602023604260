export const Tablefields = [
  {
    key: 'dispatched_date',
    label: 'Dispatched Date',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'dispatched_by',
    label: 'Dispatched By',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'waybill_number',
    label: 'Waybill Number',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'merchant_name',
    label: 'Merchant',
    thClass: 'text-left',
    tdClass: 'text-left',
    sortable: true,
  },
  {
    key: 'customer_name',
    label: 'Customer Name',
    thClass: 'text-left',
    tdClass: 'text-left',
    sortable: true,
  },
  {
    key: 'customer_email',
    label: 'Customer Email',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'customer_address',
    label: 'Customer Address',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'customer_phone',
    label: 'Customer Phone',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'warehouse_name',
    label: 'Warehouse',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'cod_value',
    label: 'COD',
    thClass: 'text-right',
    tdClass: 'text-right',
  },
]
// eslint-disable-next-line
export const json_fieldz = {
  'Dispatched Date': {
    field: 'dispatched_date',
    // eslint-disable-next-line no-underscore-dangle
    callback: value => this.__dateTimeFormatter(value),
  },
  'Dispatched By': {
    field: 'dispatched_by',
    callback: value => `"${value}"`,
  },
  'Waybill Number': 'waybill_number',
  Merchant: {
    field: 'merchant_name',
    callback: value => `"${value}"`,
  },
  'Customer Name': {
    field: 'customer_name',
    callback: value => `"${value}"`,
  },
  'Customer Email': {
    field: 'customer_email',
    callback: value => `"${value}"`,
  },
  'Customer Address': {
    field: 'customer_address',
    callback: value => `"${value}"`, // Wrap the address in double quotes
  },
  'Customer Phone': 'customer_phone',
  'Second Phone Number': 'customer_secondary_phone',
  COD: 'cod_value',
  Warehouse: {
    field: 'warehouse_name',
    callback: value => `"${value}"`,
  },
}
