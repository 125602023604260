import { RepositoryFactory } from '@/repository/RepositoryFactory'

const riderRepository = RepositoryFactory.get('rider')

export default async function getRiderList() {
  const riderOptions = localStorage.getItem('rider_list')
    ? JSON.parse(localStorage.getItem('rider_list'))
    : (await riderRepository.getAllRiderDropDown()).data.data

  if (!localStorage.getItem('rider_list')) {
    localStorage.setItem('rider_list', JSON.stringify(riderOptions))
  }

  return riderOptions
}
