<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Rider"
              label-for="rider"
            >
              <v-select
                v-model="filters['staff.id']"
                :options="riderOptions"
                :reduce="option => option.id"
                label="first_name"
                placeholder="Select Rider"
              >
                <template v-slot:option="option">
                  {{ option.first_name }} {{ option.last_name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Assigned Date"
              label-for="v-ref-no"
            >
              <div class="position-relative">
                <flat-pickr
                  id="v-ref-no"
                  v-model="filters['statuses.created_at']"
                  :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                  class="form-control"
                  placeholder="Search"
                />
                <div
                  v-if="filters['statuses.created_at']"
                  class="flatpickr-cancel"
                  @click="clearDate('statuses.created_at')"
                >
                  <feather-icon icon="XIcon" />
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div
          class="m-2"
        >
          <b-row>
            <b-col
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <div>
                <ReportTableButtons
                  :items="items"
                  :json_fieldz="json_fields"
                  :name="`${downloadReportName} - Page ${page}.xls`"
                  :bulkname="`${downloadReportName}.xls`"
                  :fetch="fetchAssignOrderPickupRiderNoPagination"
                  @refresh="fetchAssignOrderPickupRider"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-overlay
          id="overlay-background"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :json_fields="json_fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(assign_date)="data">
              {{ __dateTimeFormatter(data.value) }}
            </template>
            <template #cell(customer_phone)="data">
              <div>{{ data.item.customer_phone }}</div>
              <div>{{ data.item.customer_secondary_phone }}</div>
            </template>
            <template #cell(cod_value)="data">
              {{ __numberWithCommas(data.value, true) }}
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-sm-start"
            >
              <label class="mr-1">Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block width-125"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              md="9"
              class="d-flex align-items-center justify-content-sm-end"
            >
              <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  BPagination,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/ReportFilterButtons.vue'
import getRiderList from './RiderListDropdown'
import { Tablefields } from './TableFields'

const ReportRepository = RepositoryFactory.get('report')

export default {
  directives: {
    'b-modal': VBModal,

  },
  components: {
    BCardActions,
    vSelect,
    flatPickr,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BPagination,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      riderOptions: [],
      filters: {
        'statuses.created_at': `${new Date(new Date().setHours(0, 0, 1, 0)).toISOString()} to ${new Date(new Date().setHours(23, 59, 59, 0)).toISOString()}`,
      },
      tableData: [],
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      dataLoading: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: Tablefields,
      json_fields: {
        'Assigned Date': {
          field: 'assign_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Waybill Number': 'waybill_number',
        Merchant: {
          field: 'merchant_name',
          callback: value => `"${value}"`,
        },
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: 'cod_value',
        Rider: {
          field: 'rider',
          callback: value => `"${value}"`,
        },
      },
      currentDate: '',
      todayFilterQuery: '',
      downloadReportName: 'Assign-Order-Pickup-Rider',
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchAssignOrderPickupRider()
      },
    },
    deep: true,
    page() {
      this.fetchAssignOrderPickupRider()
    },
    perPage() {
      this.fetchAssignOrderPickupRider()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchAssignOrderPickupRider()
    this.riderList()
  },
  methods: {
    async fetchAssignOrderPickupRider() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await ReportRepository.getAssignOrderPickupRiderReport(this.page, this.filterQuery, this.perPage))
        // fiter the default date or selected date for table data and meta data
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchAssignOrderPickupRiderNoPagination() {
      try {
        const { data } = (await ReportRepository.getAssignOrderPickupRiderReportNoPagiantion(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async riderList() {
      this.riderOptions = await getRiderList()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchAssignOrderPickupRider()
    },
    applyFilters() {
      this.fetchAssignOrderPickupRider()
    },
    clearFilters() {
      this.filters = {
        'statuses.created_at': `${new Date(new Date().setHours(0, 0, 1, 0)).toISOString()} to ${new Date(new Date().setHours(23, 59, 59, 0)).toISOString()}`,
      }
      this.$nextTick(() => {
        this.fetchAssignOrderPickupRider()
      })
    },

    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select';
@import '../../../@core/scss/vue/libs/vue-flatpicker';
</style>
